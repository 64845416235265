/* Modal container styling */
.patient-bill-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  width: 80%;
  max-width: 800px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-height: 90vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Modal header */
.modal-header {
  position: relative; /* Make it a positioning context */
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.4em;
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 1.8em;
  line-height: 1;
  cursor: pointer;
  color: #999;
}
.modal-close:hover {
  color: #333;
}

/* Existing bill info styling */
.existing-bill-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  font-size: 0.9em;
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.existing-bill-info p {
  flex: 1 1 45%; /* Adjust the width of each item */
  margin: 5px 0;
}

.existing-bill-info p:nth-child(odd) {
  margin-right: 10%;
}
.existing-bill-info .excludes-fees {
  flex-basis: 100%;
  text-align: center;
  font-size: 0.8em;
  color: #999;
  padding-top: 0px;
  margin-top: 0px;
}
/* Section headers */
.patient-bill-form h3 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  font-size: 1.1em;
}

/* List styling */
.patient-bill-form ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.patient-bill-form li {
  margin-bottom: 5px;
  padding: 4px 5px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 0.9em;
}

.patient-bill-form .child-details {
  list-style-type: disc;
  margin-left: 20px;
  margin-top: 4px;
  font-size: 0.85em;
}

/* Invoice actions */
.invoice-actions {
  text-align: center;
  margin-top: 20px;
}

.invoice-actions button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.invoice-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Invoice result styling */
.invoice-result {
  text-align: center;
  margin-top: 15px;
  font-size: 1.1em;
  color: #333;
}

/* Responsive design */
@media (max-width: 600px) {
  .patient-bill-form {
    width: 90%;
    padding: 15px;
  }
}

.selected-icon {
  font-size: 1.2em;
  color: green;
  margin-right: 5px;
}

.highlight {
  background-color: #f9f9f9;
  color: red;
}

.excludes-fees {
  font-size: 0.8em;
  color: #999;
  padding-top: 0px;
  margin-top: 0px;
}
.estimated-fees {
  font-size: 1.0em;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.bill-section .form-group {
  display: flex;
  flex-direction: row;
  align-items:center; /* Align items vertically centered */
  margin-bottom: 15px;
}

.bill-section .form-group label {
  margin-right: 10px; /* Space between label and select */
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
}

.bill-section  .form-group select {
  flex: 1; /* Allow the select to take up remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  width: 100px;
}

